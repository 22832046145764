import React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/atoms/Button';
import { FluidImg } from 'src/embeds/image/image-dato';
/* new */
import { Ph, Flow, FlowGrid, Stop, Clip, Stack } from 'src/system';
import { PageHeader } from 'src/layouts/PageHeader';

const MyFlowGrid = styled(FlowGrid)`
  @media (min-width: 1024px) {
    --columns: 2;
  }
`;

const TitleCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Wrap = styled.div`
  max-width: ${({ theme }) => theme.xlargeContentWidth};
  margin: 0 auto;
  padding: 60px 1rem;
`;

// const HeaderColorState = createGlobalStyle(({ alternative, primary, theme, visible, scrolled }) => {
//   return css`
//     :root {
//       --header-fg: ${(alternative || primary) && visible ? 'white' : theme.primaryColor};
//       --header-bg: ${(alternative || primary) && visible ? theme.primaryColor : 'white'};
//       --header-sh: ${scrolled ? 'rgba(47, 17, 92, 0.13)' : 'transparent'};
//     }
//   `;
// });

export default function HeaderWithImage({
  heading,
  preHeading,
  content,
  contentNode,
  blocks,
  ctas,
  customData,
  image,
  ...rest
}) {
  const ctaBlocks = blocks.filter((block) => block.__typename == 'DatoCmsCtaBlock');
  customData = JSON.parse(customData || '{}');
  return (
    <PageHeader>
      <Wrap>
        <MyFlowGrid style={{ '--gap-x': '40px', '--gap-y': '60px' }}>
          <TitleCell className="cell">
            <Stack style={{ '--gap-y': '30px' }}>
              <h1 style={{ margin: 0 }}>{heading}</h1>
              {/* {content.trim().length > 0 ? <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer> : null} */}
              {ctaBlocks?.length > 0 ? (
                <div>
                  {ctaBlocks.map((cta) => (
                    <Button key={cta.text} to={cta.href}>
                      {cta.text}
                    </Button>
                  ))}
                </div>
              ) : null}
            </Stack>
          </TitleCell>
          <div className="cell">
            <div>
              <FluidImg data={image} />
            </div>
          </div>
        </MyFlowGrid>
      </Wrap>
    </PageHeader>
  );
}
